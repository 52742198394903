import React, { useState } from "react"
// import dots from "../../../static/images/Group 237.png"
import memberIcon from "../../../static/images/memberIcon.png"
import phonintrest from "../../../static/images/PhoneImage.png"
import msgint from "../../../static/images/ChatImage.png"
import Group236 from "../../../static/images/sharing.png"
import closebutton from "../../../static/images/Icon ionic-ios-close.png"
import * as styled from "../buyers/buyer.module.css"
import { Button, Card } from "react-bootstrap"
import { Link } from "gatsby";
import AvatarImage from "../../components/Avatar/avatarImage"
// import { RWebShare } from "react-web-share"
// import { useLocation } from '@reach/router';
import ChatPopup from '../../components/popup/ChatPopup'
import { useSelector } from "react-redux";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import SharePopup from "../../components/popup/sharePopup"
import * as style1 from "../../pages/dashboard/detail.module.css"
import Loader from "../../components/Loader"
import Dummy from "../../../static/images/house4.png"
import PropertyDetails from "../../components/popup/propertDetails"
import { createBrowserHistory } from 'history';
import PropMap from "../../components/Seller/PropMap"
import { ChatData,formatPrice } from "../../common/GenericFunction"

function InboundDetail(props) {
    const [open, setOpen] = useState(false)
    // const location = useLocation();
    const history = createBrowserHistory();
    const showDetail = () => {
        setOpen(true)
    }
    const inboundDetail = props.data
    // console.log("inbound", inboundDetail)
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const userDetails = useSelector(state => state.user.userDetail);
    const userlocation = useSelector(state => state.user.userLocation);
    const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
    const [openChat, setOpenChat] = useState(false)
    const [loader, setLoader] = useState(false);
    const [openProperty, setOpenProperty] = useState(false)
    const showChatDetail = () => {
        if (openChat === false) {
            setLoader(true);
        }
        else {
            setLoader(false);

        }
        setOpenChat(p => !p)
    }
    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const handleChat = async (id, name, lastName, image) => {

        let names = name
        await chattingUserData !== null && chattingUserData.disconnectUser()
        const apikey = process.env.STREAM_CHAT_APIKEY;

        const chatClient = StreamChat.getInstance(apikey);
        await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
        await chatClient.disconnectUser()

        setChatAgent({ id: id, name: names, image: image })
        setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
        setChatId(id + userDetails._id)
        showChatDetail()
    }
    const [shows, setShows] = useState(false)
    const toggleShows = () => setShows(p => !p)
    const makeCall = (PhoneNumber) => {
        if (PhoneNumber !== null && PhoneNumber !== "") {
            window.location.href = 'tel://' + PhoneNumber;

        }
    }
    // propert details popup //
    const hanldeOpenDetails = (data) => {
        setOpenProperty(p => !p)
        // history.push(`/referralFee/${data.address}`) // when popup open
        window.open(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`,"_blank")
    }
    const hanldeOpenDetailsClose = () => {
        setOpenProperty(p => !p)
        history.push(`/referralFee`) // when popup open

    }
    return (

        <div className="prospectback mb-5">
            <SharePopup shows={shows} toggleShows={toggleShows} id={inboundDetail.property_id !== null && inboundDetail.property_id._id} />
            {/* {openProperty &&  <PropertyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetailsClose} id={inboundDetail.property_id !== null && inboundDetail.property_id._id} propertDetails={inboundDetail.property_id} />} */}
            <Card className="prospectCard" >
                <div>
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-6" style={{ textAlign: "right" }}>
                            {inboundDetail.referred_by !== null && inboundDetail.referred_by.img ?
                                <img alt="no_image" src={process.env.API_IMAGE_URL + inboundDetail.referred_by.img} className={styled.avatarimage} crossOrigin="anonymous" />
                                : <>{inboundDetail.referred_by !== null && inboundDetail.referred_by.name ? <div style={{ width: '100px', height: '100px', position: "relative", margin: "auto", marginRight: "18px" }}><AvatarImage fontSize={'40px'} data={inboundDetail.referred_by.name ? inboundDetail.referred_by.name : ''} /></div>
                                    : null}</>
                            }
                        </div>
                        <div className="col-md-6">
                            <h6 className="text-capitalize buyerName">{inboundDetail.referred_by.name}</h6>
                            <div style={{ cursor: "pointer", display: "flex" }}>
                                <img src={msgint} alt="msgint" className="msgint1"
                                    onClick={() => { handleChat(inboundDetail.referred_by._id, inboundDetail.referred_by.name, inboundDetail.referred_by.last_name, inboundDetail.referred_by.img ? hanldeImage(inboundDetail.referred_by.img) : "");ChatData("Inbound Chat", userDetails, inboundDetail.referred_by._id,userlocation) }}
                                    onKeyPress={() => { handleChat(inboundDetail.referred_by._id, inboundDetail.referred_by.name, inboundDetail.referred_by.last_name, inboundDetail.referred_by.img ? hanldeImage(inboundDetail.referred_by.img) : ""); }} aria-hidden
                                />
                                {inboundDetail.referred_by.phone_number !== undefined && inboundDetail.referred_by.phone_number !== null && inboundDetail.referred_by.phone_number !== "" &&
                                    <span style={{ cursor: "pointer" }} className={styled.tooltip}>
                                        <img onClick={() => makeCall((inboundDetail.referred_by.phone_number !== undefined && inboundDetail.referred_by.phone_number !== null && inboundDetail.referred_by.phone_number !== "") ? inboundDetail.referred_by.phone_number : null)} onKeyPress={() => makeCall((inboundDetail.referred_by.phone_number !== undefined && inboundDetail.referred_by.phone_number !== null && inboundDetail.referred_by.phone_number !== "") ? inboundDetail.referred_by.phone_number : null)} src={phonintrest} aria-hidden alt="phonintrest" className={styled.phonintrest} />
                                        {inboundDetail.referred_by.phone_number !== undefined && inboundDetail.referred_by.phone_number !== null && inboundDetail.referred_by.phone_number !== "" ?
                                            <span className={styled.tooltiptext}>{inboundDetail.referred_by.phone_number}</span> : null
                                        }

                                    </span>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="interaddress mb-4">
                        <h6 style={{ fontFamily: "DejaVuSansBold", marginBottom: "12px" }}> {inboundDetail.property_id !== null && inboundDetail.property_id.address.split(',').slice(0).shift()}</h6>
                        <p>{inboundDetail.property_id !== null ? inboundDetail.property_id.address.split(",").slice(1).join(",") : ""}</p>
                        <p>${formatPrice(inboundDetail.property_id !== null ? inboundDetail.property_id.price.$numberDecimal : "")}</p>
                    </div>
                    <div>
                        <Button variant="primary" className={styled.viewbut2} onClick={showDetail}>
                            View Property Details
                        </Button>
                        {open ? (
                            <div>
                                <div className={styled.imagedetails}>
                                    {/* <Link to={`/dashboard/detail/${inboundDetail.property_id !== null && inboundDetail.property_id._id}`} style={{ textDecoration: "none" }}> */}
                                    {inboundDetail.property_id.thumbnail_image !== undefined && inboundDetail.property_id.thumbnail_image !== null ?
                                        <>
                                            {inboundDetail.property_id.thumbnail_image !== undefined && inboundDetail.property_id.thumbnail_image.split("/")[0] !== "uploads" ?
                                                <img src={hanldeImage(inboundDetail.property_id !== null && inboundDetail.property_id.thumbnail_image)} alt="mask192" className={styled.inthomeImage} onClick={() => hanldeOpenDetails(inboundDetail.property_id)} /> :
                                                <img src={hanldeImage(inboundDetail.property_id !== null && inboundDetail.property_id.thumbnail_image)} alt="mask192" className={styled.inthomeImage} crossOrigin="anonymous" onClick={() => hanldeOpenDetails(inboundDetail.property_id)} />}
                                        </> :
                                        // <img src={Dummy} alt="mask192" className={styled.inthomeImage} crossOrigin="anonymous" onClick={hanldeOpenDetails} />}
                                        <PropMap lng={inboundDetail.property_id.location.coordinates[0]} lat={inboundDetail.property_id.location.coordinates[1]} />}
                                    {/* </Link> */}
                                    <img src={closebutton} alt="closebutton" className={styled.closebutton2} onClick={() => setOpen(false)} onKeyPress={() => setOpen(false)} aria-hidden />
                                    <div className="shareButton2" >
                                        <img src={Group236} alt="group236" className="shareIcon2" onClick={inboundDetail.property_id !== null && toggleShows} onKeyPress={inboundDetail.property_id !== null && toggleShows} aria-hidden />
                                    </div>
                                    {/* {inboundDetail.property_id.listing_status === 3 &&
                                        <div className={styled.memberdetails}>
                                            <img src={memberIcon} alt="memberIcon" className={styled.memberIcon} />
                                            <button type="button" className={styled.memberbut2}>Exclusive</button>
                                        </div>
                                    } */}
                                </div>
                                <div className={styled.housedetail}>
                                    <div className="d-flex" style={{ marginLeft: "17px" }}>
                                        <i className="fa fa-circle ClaimActiveIcon " style={{ color: inboundDetail.property_id.listing_status === 0 ? "orange" : inboundDetail.property_id.listing_status === 1 || inboundDetail.property_id.listing_status === 2 ? "#03B45B" : inboundDetail.property_id.listing_status === 4 ? "yellow" : inboundDetail.property_id.listing_status === 6 ? "grey" : "red" }} ></i>
                                        <p className={style1.housetext1}>{inboundDetail.property_id.listing_status === 0 ? "Draft" : inboundDetail.property_id.listing_status === 1 ? "Active" : inboundDetail.property_id.listing_status === 2 ? "Active Contingent" : inboundDetail.property_id.listing_status === 3 ? "Exclusive" : inboundDetail.property_id.listing_status === 4 ? "Pending" : inboundDetail.property_id.listing_status === 5 ? "Sold" : inboundDetail.property_id.listing_status === 6 ? "Expired" : inboundDetail.property_id.listing_status === 7 ? "Cancelled" : inboundDetail.property_id.listing_status === 8 ? "Withdrawn" : "Keywe Exclusive"}</p>
                                    </div>
                                    <p className={styled.pricehouse}>${formatPrice(inboundDetail.property_id.price.$numberDecimal)}</p>
                                </div>
                                <div className={styled.headContent}>
                                    <h5 className={styled.intaddess}>{inboundDetail.property_id.address.split(',').slice(0).shift()}</h5>
                                    <h5 className={styled.intaddess}>{inboundDetail.property_id.address.split(",").slice(1).join(",")}</h5>
                                </div>
                                <div className={styled.dashboardActive2}>
                                    <i className="fa fa-circle" style={{ color: "green" }}></i>
                                    <p style={{ marginLeft: "5px" }}>Actively Showing</p>
                                </div>
                                <div>
                                    <Card className={styled.detCard} style={{ borderRadius: "5px", boxShadow: "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)" }}>
                                        <div className="row m-0">
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{inboundDetail.property_id.beds}</p>
                                                <p style={{ color: "#898686" }}>Beds</p>
                                            </div>
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{inboundDetail.property_id.baths}</p>
                                                <p style={{ color: "#898686" }}>Baths</p>
                                            </div>
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{formatPrice(inboundDetail.property_id.square_feet.$numberDecimal)}</p>
                                                <p style={{ color: "#898686" }}>Sq. Feet </p>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Card>
            <>
                {loader ? <Loader /> : null}
                {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}</>
        </div>
    )
}
export default InboundDetail

